<template>
  <div class="apiDocument-container" >
    <div class="basicContainer" >
      <a-layout >
        <a-layout-header style="height: 80px; background-color: rgb(255, 255, 255); padding: 0;">
          <div class="fl-header headeron">
            <div class="fl-headerinner">
              <div class="headerlog">
                <a href="/">
                  <img src="http://dx-bs-oss.oss-cn-shanghai.aliyuncs.com/uploadfile/20240329/632164358718349312.png" alt=""></a>
              </div>
              <div class="headermenu">
                <ul class="cf">
                  <li v-for="i in docList">
                    <a   :href="'?docid=' + i.id"  :class="{active : docId == i.id}">{{i.name}}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </a-layout-header>
        <a-layout-content>

          <div class="base-box">
            <view class="document-box">
              <a-layout>
                <a-layout-sider width="280px">
                  <div class="siderBar-menu" >
                    <a-menu :open-keys="openKeys" mode="inline" :selected-keys="current" @click="handleClick" @openChange="onOpenChange">
                      <template v-for="item in docMenu">
                        <a-menu-item v-if="!item.children" :key="item.id" :title="item.label"  @click="onMenu($event, [item])">
                          {{ item.label }}
                        </a-menu-item>

                        <a-sub-menu v-else :key="item.id">
                          <template #title>{{ item.label }}</template>

                          <template v-for="childItem in item.children" :key="childItem.id">
                            <a-menu-item v-if="!childItem.children" :key="childItem.id"  :title="childItem.label"  @click="onMenu($event, [item,childItem])">
                              <span style="font-size: 14px">{{ childItem.label }}</span>
                              <template #title style="font-size: 16px" >{{ childItem.label }}</template>

                            </a-menu-item>
                            <a-sub-menu v-else :key="childItem.id">
                              <template #title style="font-size: 14px">{{ childItem.label }}</template>

                              <template v-for="sbuChildItem in childItem.children" :key="sbuChildItem.id">
                                <a-menu-item v-if="!sbuChildItem.children"  :title="sbuChildItem.label" :key="sbuChildItem.id" @click="onMenu($event,[item,childItem,sbuChildItem])">
                                  {{ sbuChildItem.label }}
                                </a-menu-item>
                                <a-sub-menu v-else :key="sbuChildItem.id">
                                  <template #title  style="font-size: 14px">>{{ sbuChildItem.label }}</template>
                                </a-sub-menu>
                              </template>
                            </a-sub-menu>
                          </template>

                        </a-sub-menu>
                      </template>
                    </a-menu>
                  </div>
                </a-layout-sider>
                <a-layout-content style="width: 948px; margin-right: 10px;background: #FAFCFF;">
                  <div class="ant-layout-content"  style="width: 948px; margin-right: 10px; text-align: left; ">
                    <a-breadcrumb class="breadcrumb">
<!--                      {{docList}}-->
                      <a-breadcrumb-item v-if="indexHome.name"><a :href="indexHome.href">{{ indexHome.name }}</a></a-breadcrumb-item>

                      <a-breadcrumb-item v-for="(v, k) in breadcrumbData" :key="k">
                        <span  v-if=" v  && v.label"> {{ v.label }}</span>
                      </a-breadcrumb-item>

                    </a-breadcrumb>

                    <div class="x-ui-markdown-main markdown-body markdown-continer">
                      <div v-html="content" @click="handleClick"></div>
                    </div>
                  </div>
                </a-layout-content>
                <div class="my-strack-navigator" style="display: block; text-align: left;  margin-right: 100px;" >
                  <div style="">
                    <div class="" >
                      <a-anchor :offsetTop="80" :targetOffset="180" :showInkInFixed="true">
                        <div class="nav-title">本页目录：</div>
                        <a-anchor-link v-for="(v, k) in markdownMenu" :href="'#'+ (k+1)" :class="{h1:v.type == 1, h2:v.type == 2, }"  :title="v.name" ></a-anchor-link>
                      </a-anchor>
                    </div>
                  </div>
                </div>
              </a-layout>
            </view>
          </div>
        </a-layout-content>

        <a-layout-footer></a-layout-footer>
      </a-layout>
    </div>
    <div class="markdown-continer" v-if="viewImg" >
      <div class="zoom-mask-img" style="display: flex;">
        <i aria-label="图标: close" tabindex="-1" class="anticon anticon-close" @click="viewImg =''">
          <svg viewBox="64 64 896 896" class="" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false">
            <path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
          </svg>
        </i>
        <div class="zoom-wrap">
          <img :src="viewImg" alt=""></div>
      </div>
    </div>

  </div>
</template>

<script>
import MarkdownIt from 'markdown-it'
import request from '../utils/request'
const md = new MarkdownIt();

export default {
  name: 'HelloWorld',
  components: {
  },
  computed: {
    content : function () {
      let that = this;
      let html =  (md.render(this.markdownContent));
      let n = 0
      html = html.replace(/&gt;/g, '>').replace(/&lt;/g, '<').replace(/&quot;/g, '"')
      html = html.replace(/<img/g, '<img id="img"');

/*
      let newStr = html.replace(/<h1>(.*?)<\/h1>/g, function (i){
        n ++;
        let title  = i.replace('<h1>', '').replace('</h1>', '');
        that.markdownMenu[n-1] = title
        return '<h1 id="'+ n +'">' + title + '</h1>';
      });*/



      let newStr = html.replace(/(<h1>(.*?)<\/h1>)|(<h2>(.*?)<\/h2>)/g, function (i){
        n ++;
        let title = '';
        let type = 1;
        if(i.indexOf('h1') == 1) {
          title  = i.replace('<h1>', '').replace('</h1>', '');
        }else if(i.indexOf('h2') == 1){
          title  = i.replace('<h2>', '').replace('</h2>', '');
          type = 2;
        }


        let markdownMenuTitle = title
            .replace(/<(.*?)>/g, '|')
            .replace(/<(.*?)>/g, '|')
            .replace(/\|\|(.*?)\|\|/g, '')

        console.log(markdownMenuTitle)
        that.markdownMenu[n-1] = {name:markdownMenuTitle, type: type}

        if(i.indexOf('h1') == 1) {
          return '<h1 id="'+ n +'">' + title + '</h1>';
        }else if(i.indexOf('h2') == 1){
          return '<h2 id="'+ n +'">' + title + '</h2>';
        }
      });


/*
      let newStr = html.replace(/<h2>(.*?)<\/h2>/g, function (i){
        n ++;
        let title  = i.replace('<h2>', '').replace('</h2>', '');
        that.markdownMenu[n-1] = title
        return '<h2 id="'+ n +'">' + title + '</h2>';
      });*/

      return newStr;
    }
  },
  props: {
    msg: String
  },

   mounted() {
     let params = new URLSearchParams(window.location.search);
     let docid = params.get('docid');
     let hash = window.location.hash;
     if(hash) this.hashId = hash
     if(!docid) docid = 1;// 默认文档ID
     let pageid = params.get('pageid');



    request.get('/apidoc/getDoc').then(res=>{
      this.docList = res.data;
      res.data.map(i=>{ this.indexPage[i.id] = {name: i.name, path:'?docid=' + i.id}})
    })


    request.get('/apidoc/getDocMenu?doc_id=' + docid).then(res=>{
      this.docId = docid
      this.docMenu = res.data.menu;
      if(!pageid){ pageid = res.data.page_id ? res.data.page_id : 0 }

      for (let i1 in this.docMenu){
        /*
        if(!this.docMenu[i1].children) continue;
        for (let i2 in this.docMenu[i1].children){

        }*/
      }

      if(pageid) this.getMarkdownContent(parseInt(pageid))

    }).catch(message => reject({ message }))
  },

  data() {
    return {
      docList: [],
      docId: '',
      pageId: '',
      hashId: '',

      rootSubmenuKeys: [],
      openKeys: [],
      current: [],
      docMenu: [],
      indexPage: {},
      indexHome:{name:'', href:''},
      breadcrumbData:[],
      markdownMenu:[],
      markdownContent:'',
      viewImg:'',
    }
  },
  methods: {
    onMenu(e, v) {
      this.breadcrumbData = v;
      // window.location.href = "?docid=" + this.docId + '&pageid=' + this.pageId
      this.getMarkdownContent(v[v.length-1].id)
      this.pageId = v[v.length-1].id;

    },

    getMarkdownContent(id){
      request.get('/apidoc/getDocMenuInfo', {id:id}).then(res=>{
        this.pageId = id;
        let url =  "?docid=" + this.docId + '&pageid=' + this.pageId;
        if(this.hashId) url+'#=' + this.hashId;
        history.pushState({}, '心链开放平台文档中心',  url);
        this.markdownMenu = []
        this.markdownContent = res.data.content ? res.data.content : '';
        this.openKeys = res.data.parent_id;
        this.current = [id];
        this.indexHome = this.indexPage[this.docId]
        console.log(this.hashId)
        setTimeout(()=>{
/*          let container = this.$refs.basicContainer;
      console.log(container.scrollHeight);
      console.log(document.body)
      document.body.scrollTop = container.scrollHeight
          container.scrollTop = container.scrollHeight;*/
          if(this.hashId) {
            window.location.hash = this.hashId
            this.hashId = '';
          }
        }, 500)
      })
    },

    handleClick(event) {
      // 使用event.target来判断是不是我们想要的元素
      if (event.target && event.target.id === 'img') {
        this.viewImg = event.target.src
      }
    },
    onOpenChange(openKeys) {
      const latestOpenKey = openKeys.find((key) => this.openKeys.indexOf(key) === -1)
      if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        this.openKeys = openKeys
      } else {
        this.openKeys = latestOpenKey ? [latestOpenKey] : []
      }
      console.log(this.openKeys)
    },
  },

}

</script>
<style scoped>
.fl-header {
  position: fixed;
  top: 0;
  z-index: 200;
  width: 100%;
  height: 80px;
  font-size: 16px;
  color: #666;
  -webkit-box-shadow: 0 1px 0 0 hsla(0,0%,100%,.1);
  box-shadow: 0 1px 0 0 hsla(0,0%,100%,.1);
  background-color: transparent;
  -webkit-transition: all .3s cubic-bezier(.645,.045,.355,1);
  -o-transition: all .3s cubic-bezier(.645,.045,.355,1);
  transition: all .3s cubic-bezier(.645,.045,.355,1)
}

.fl-headerinner {
  width: 1280px;
  height: 100%;
  margin: 0 auto
}

.fl-headerinner .headerlog {
  position: relative;
  float: left;
  width: 226px;
  height: 80px;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.fl-headerinner .headerlog img {
  width: 100%;
  cursor: pointer
}

.headeractive,.headeron {
  background-color: #fff;
  -webkit-box-shadow: 0 1px 0 0 hsla(0,4%,67%,.1);
  box-shadow: 0 1px 0 0 hsla(0,4%,67%,.1)
}

.headeractive .fl-headerinner .headerlog a,.headeron .fl-headerinner .headerlog a {
}

.headermenuphone {
  display: none
}

.fl-headerinner .headermenu {
  float: left;
  margin-left: 50px;
  height: 80px
}

.fl-headerinner .headermenu ul {
  height: 80px
}

.fl-headerinner .headermenu li {
  float: left;
  margin: 0 10px;
  position: relative;
  height: 80px
}

.fl-headerinner .headermenu li.active:after {
  content: "";
  display: block;
  width: 27px;
  height: 4px;
  background: #336aff;
  position: absolute;
  bottom: 0;
  left: calc(50% - 13px)
}

.fl-headerinner .headermenu li a {
  display: block;
  height: 80px;
  line-height: 80px;
  color: #fff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0 25px;
  font-size: 16px;
  border-bottom: 2px solid #fff
}

.fl-headerinner .headermenu li a.active {
  border-bottom-color: #0e9cff
}

.fl-headerinner .headermenu li a.solution {
  padding-right: 36px
}

.headeractive .fl-headerinner .headermenu li a,.headeron .fl-headerinner .headermenu li a {
  font-size: 16px;
  color: #333
}

.fl-headerinner .headermenu li .moremenu {
  display: none;
  position: absolute;
  overflow: hidden;
  width: 152px;
  max-height: 255px;
  padding: 7px 16px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  top: 80px;
  left: 0;
  background: #fff;
  -webkit-box-shadow: 0 9px 28px 8px rgba(0,0,0,.05),0 6px 16px 0 rgba(0,0,0,.08),0 3px 6px -4px rgba(0,0,0,.12);
  box-shadow: 0 9px 28px 8px rgba(0,0,0,.05),0 6px 16px 0 rgba(0,0,0,.08),0 3px 6px -4px rgba(0,0,0,.12);
  border-radius: 2px
}

@-webkit-keyframes mymove {
  0% {
    max-height: 0
  }

  to {
    max-height: 255px
  }
}

@keyframes mymove {
  0% {
    max-height: 0
  }

  to {
    max-height: 255px
  }
}

.fl-headerinner .headermenu li:hover .dropdownmenu+.moremenu {
  display: block;
  -webkit-animation: mymove .3s 1;
  animation: mymove .3s 1
}

.fl-headerinner .headermenu li:hover a {
  color: #0e9cff
}

.fl-headerinner .headermenu li .moremenu dl dt {
  line-height: 50px;
  height: 50px;
  margin-top: 15px;
  padding-left: 30px;
  color: #575a66;
  font-size: 16px
}

.fl-headerinner .headermenu li .moremenu dl dd {
  height: 34px
}

.fl-headerinner .headermenu li .moremenu dl dd a {
  display: block;
  height: 34px;
  line-height: 34px;
  font-size: 14px;
  color: #000;
  border-radius: 2px;
  -webkit-transition: none;
  -o-transition: none;
  transition: none
}

.fl-headerinner .headermenu li .moremenu dl dd a.on,.fl-headerinner .headermenu li .moremenu dl dd a:hover {
  color: #fff;
  background: #0e9cff
}

.fl-headerinner .headermenu li .moremenu dl dd a.disabled {
  color: #ccc
}

.fl-headerinner .headermenu li .moremenu dl dd a.disabled:hover {
  background: #fff;
  color: #ccc;
  cursor: no-drop
}

.fl-headerinner .headermenu li .dropdownmenu {
  position: relative
}

.fl-header .fl-headerinner .headermenu li .dropdownmenu:after {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  top: 35px;
  right: 16px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAAXNSR0IArs4c6QAAAFZJREFUGBljYBh84P///0xAHAVzGROIARRwAuJHQGwO5YPEFwExL4iPAoCKXIH4DRBbAvESIN4DxCwoimAcoIQZEB8F4uNAzAETx0oDFegQVIRVJ6mCAFCCPQGB4IslAAAAAElFTkSuQmCC)
}

.fl-header .fl-headerinner .headermenu li .dropdownmenu:hover:after {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  top: 35px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAAXNSR0IArs4c6QAAAEpJREFUGBljYKA5+P//vwkQS+O1CKigCIh/APFTIJbBqhiq6C6QlgbiQiAGsflRFAMFwoB4ORCLwCSAbJDiShgfLw1UaIdXAX0kAeknPYWOKaMpAAAAAElFTkSuQmCC)
}

.fl-header.headeractive .fl-headerinner .headermenu li .dropdownmenu:after,.fl-header.headeron .fl-headerinner .headermenu li .dropdownmenu:after {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAAXNSR0IArs4c6QAAAIZJREFUGBljYBgwwIzL5oaGBiZefuXIWzfPXwapYQERvoEpTn///13AwMwQum3t/JMgRafPP1r0n4HxMEgeBBghFAODX2CKK1DxckZGRt///xiygTISpgZyHkBNf1AUgjg+Aclm/xn+9f//z8AkJijvuGBBww+YQRi0d2CyTkJCAweGBNUFAA3YKk9y4dHEAAAAAElFTkSuQmCC)
}

.fl-header.headeractive .fl-headerinner .headermenu li .dropdownmenu:hover:after,.fl-header.headeron .fl-headerinner .headermenu li .dropdownmenu:hover:after {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAAXNSR0IArs4c6QAAAHVJREFUGBljYBg8IDS0kDMhoYED3UVMyAI+AclmX39/uPn6w8O96IrhCv0CU1z/M/zbxsTIGM7AwPjw1YeHmxoaGlhgBoEV+gamOP35/28eIwOT15b1846bGsjFMDIwvjl94VESTCFOGmgak09gUjROBRRLAACZRyXDBpuOvgAAAABJRU5ErkJggg==)
}

/deep/ .my-strack-navigator .h1 a{ font-size: 14px !important;  color: #1d2129 !important; }
/deep/ .my-strack-navigator .h2 a{ font-size: 13px !important;  color: #4e5969 !important; text-indent: 10px;}
/deep/ .my-strack-navigator .h1 .ant-anchor-link-title-active{ font-size: 14px !important;  color: #3983d7 !important; }
/deep/ .my-strack-navigator .h2 .ant-anchor-link-title-active{ font-size: 13px !important;  color: #3983d7 !important; text-indent: 10px;}
/deep/ .my-strack-navigator .ant-anchor-link{ width: 140px;}
/deep/ .my-strack-navigator .h1:hover {
  color: #3983d7 !important;
}


.markdown-continer .zoom-mask-img {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(0,0,0,.8);
  z-index: 201;
  overflow-y: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center
}

.markdown-continer .zoom-mask-img .anticon-close {
  color: #fff;
  position: absolute;
  right: 12px;
  top: 12px;
  font-size: 18px;
  cursor: pointer;
  -webkit-transition: all .1s;
  -o-transition: .1s all;
  transition: all .1s
}

.markdown-continer .zoom-mask-img .anticon-close:hover {
  color: #00a4ff
}

.markdown-continer .zoom-mask-img .zoom-wrap {
  display: flow;
  vertical-align: middle;
}

.markdown-continer .zoom-mask-img .zoom-wrap>img {
  display: inline;
  width: auto;
  height: auto;
  max-width: 80%;
  max-height: 80%;
  cursor: pointer
}


</style>
