import { createApp } from 'vue'
import App from './App.vue'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';

import '@/assets/css/main.css'
import '@/assets/css/doc.css'

// 引入vue-router
import VueRouter from 'vue-router'


const app = createApp(App);
app.config.productionTip = false;

app.use(Antd).use(VueRouter).mount('#app');
